<template>
  <button
    class="ib-navigation-btn-next d-flex justify-content-between align-items-center"
    :class="{'disabled': disabled}"
    @click="onClick"
  >
    <span class="d-flex flex-column justify-content-center align-items-start text-left mr-3">
      <span
        class="text text-uppercase"
        :class="{'no-title': !title}"
      >
        {{ btnText }}
      </span>
      <span v-if="title" class="title mt-1">{{ title }}</span>
    </span>
    <i :class="icon" />
  </button>
</template>

<script>
import MixinUserSubscription from '@/mixins/permissions/userSubscription'

export default {
  name: 'IbNavigationButtonNext',

  mixins: [MixinUserSubscription],

  props: {
    text: {
      type: String,
      default: null
    },

    title: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    isNexSectionMarket: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    btnText () {
      if (this.text) return this.text

      return this.$t('next')
    },

    icon () {
      if (this.subscriptionIsFreeTrial && this.isNexSectionMarket) {
        return 'ib-icon-lock-2 icon'
      }

      return 'el-icon-right icon'
    }
  },

  methods: {
    onClick () {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>

<style scoped lang="scss">
.ib-navigation-btn-next {
  min-width: 200px;
  min-height: 56px;
  padding: 10px 15px;
  border: unset;
  border-radius: 8px;
  cursor: pointer;
  background-color: $color-primary;

  .title, .icon {
    opacity: 0.5;
    color: $color-white;
  }

  .icon {
    font-size: 30px;
  }

  .text {
    font-family: $outfit-semi-bold;
    font-size: 14px;

    &.no-title {
      font-size: 16px;
    }
  }

  .title {
    font-size: 12px;

    @include media-breakpoint-up($md) {
      font-size: 14px;
    }
  }

  .text, .title {
    line-height: 1.21;
    letter-spacing: 1px;
    color: $color-white;
  }

  &.disabled {
    box-shadow: none;
    background-color: #a2b7e1;
    cursor: not-allowed;

    .text {
      color: $color-text-light-blue;
    }

    .title, .icon {
      color: rgba(65, 109, 198, 0.5);
    }
  }
}
</style>
